// seo
import Seo from "./components/action/seo/seo";
// router
import Router from "./components/action/router/router";

const App = () => {
    return (
        <>
            {/* seo */}
            <Seo
                title="ایران تراکنش | سامانه ملی امن ، سریع و آسان"
                description="ایران تراکنش سامانه ملی امن ، سریع و آسان برای مردم ایران"
                keywords="ایران،تراکنش،ایران تراکنش،سامانه،ملی،سریع،آسان"
                type="webapp"
                name="progogram"
            />
            {/* content */}
            <Router/>
        </>
    );
}

export default App;
