const Footer = () => {
    return (
        <>
            <footer id={'footer'} className={'footer mt-5 mb-md-4'}>
                <section id={'footer-section'} className={'text-white container shadow pb-xl-5 pb-5'}>
                    <svg xmlns={'http://www.w3.org/2000/svg'} className='w-100 svg-footer' width="100%" height="2"
                         viewBox="0 0 340 2" fill="none">
                        <path d="M0 1L340 1.00003" stroke="url(#paint0_linear_85_83)" strokeWidth="2"/>
                        <defs>
                            <linearGradient id="paint0_linear_85_83" x1="340" y1="1.50397" x2="1.88407e-06" y2="1.50524"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#42c7bf" stopOpacity="0"/>
                                <stop offset="0.502078" stopColor="#42c7bf"/>
                                <stop offset="1" stopColor="#42c7bf" stopOpacity="0"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className={'row align-items-center justify-content-center pt-4 pb-0'}>
                        <div className={'col-xl-12 px-md-5 px-3'}>
                            <div className={'description text-center'}>
                                <img src={require('./../../../assets/media/logo/Logo_Iran Tarakonesh_Vr_34.png')}
                                     alt={'logo'}
                                     className={'img-fluid logo'}/>
                                <p className={'h5 mt-4 fw-bold text-green card-title fw-bold text-center'}>
                                    ایران تراکنش
                                </p>
                                <p className={'card-text mb-0 text-dark footer-subtitle mt-3 text-center'}>
                                    سامانه ملی امن ، سریع و آسان
                                </p>
                            </div>
                        </div>
                        <div className={'col-xl-12 mt-md-0 px-md-3 px-0'}>
                            <div className={'container mt-4'}>
                                <div className={'row d-flex'}>
                                    <div className={'col-12 end-bg w-100'}>
                                        <div className={'d-flex justify-content-center social-footer'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="142" height="30"
                                                 viewBox="0 0 142 30" fill="none">
                                                <path
                                                    d="M127.206 29.7692C119.048 29.7692 112.412 23.1339 112.412 14.9753C112.412 6.81669 119.048 0.181396 127.206 0.181396C135.365 0.181396 142 6.81669 142 14.9753C142 23.1339 135.365 29.7692 127.206 29.7692Z"
                                                    fill="#00BAF9"/>
                                                <path
                                                    d="M124.713 18.8462L124.577 22.3608L126.342 20.1489L124.713 18.8462Z"
                                                    fill="white"/>
                                                <path
                                                    d="M134.333 8.0169C131.516 9.18391 122.416 13.083 118.973 14.5576C118.519 14.7521 118.55 15.426 119.021 15.5753L122.97 16.8418L131.824 11.3234C131.877 11.2918 131.934 11.3641 131.885 11.4094L125.114 18.0224C125.114 18.0224 129.275 21.1525 131.251 22.6316C131.736 22.998 132.427 22.7176 132.537 22.1069C133.105 19.0039 134.523 11.242 135.007 8.58684C135.082 8.18879 134.694 7.86311 134.329 8.0169H134.333Z"
                                                    fill="white"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M124.23 22.0333C124.23 22.0062 124.534 22.0333 124.23 22.0333C124.23 22.017 124.268 22.0333 124.23 22.0333Z"
                                                      fill="white"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M130.329 7.89551C130.329 7.89551 130.285 7.91189 130.258 7.89551H130.329Z"
                                                      fill="white"/>
                                                <path
                                                    d="M52.6323 29.7746C44.4737 29.7746 37.8384 23.1393 37.8384 14.9807C37.8384 6.82211 44.4737 0.181396 52.6323 0.181396C60.7909 0.181396 67.4262 6.81669 67.4262 14.9753C67.4262 23.1339 60.7909 29.7692 52.6323 29.7692V29.7746Z"
                                                    fill="#62DB77"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M52.6521 6.4187C47.9216 6.4187 44.0757 10.2645 44.0757 14.9951C44.0757 16.6604 44.5493 18.2581 45.4444 19.6423L44.1434 22.5098C44.0237 22.7752 44.0653 23.0823 44.2579 23.306C44.4036 23.4778 44.6118 23.5714 44.8251 23.5714C44.8928 23.5714 44.9552 23.561 45.0229 23.5454L48.6137 22.5619C49.8523 23.2228 51.2418 23.5714 52.6521 23.5714C57.3827 23.5714 61.2285 19.7256 61.2285 14.9951C61.2285 10.2645 57.3827 6.4187 52.6521 6.4187ZM52.6521 22.0727C51.3979 22.0727 50.1698 21.7396 49.0873 21.1099C48.9728 21.0423 48.8427 21.0058 48.7074 21.0058C48.6397 21.0058 48.5721 21.0162 48.5096 21.0318L46.1626 21.6772L46.9796 19.8765C47.0889 19.6319 47.0629 19.3457 46.9068 19.1271C46.0325 17.9146 45.5693 16.4887 45.5693 14.9899C45.5693 11.0868 48.7438 7.91228 52.6469 7.91228C56.55 7.91228 59.7245 11.0868 59.7245 14.9899C59.7245 18.893 56.55 22.0675 52.6469 22.0675L52.6521 22.0727Z"
                                                      fill="white"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M56.3159 16.2452C55.952 16.0338 55.4774 15.7968 55.0503 15.9668C54.7234 16.1009 54.5124 16.6008 54.2962 16.8585C54.1855 16.9925 54.0589 17.0131 53.8902 16.9461C52.6563 16.4668 51.7071 15.6576 51.0269 14.5495C50.9108 14.3795 50.9319 14.2403 51.069 14.0805C51.2747 13.8435 51.5331 13.5755 51.5911 13.2559C51.6491 12.9364 51.4962 12.5653 51.3591 12.2818C51.185 11.9211 50.9952 11.4005 50.6208 11.1944C50.2781 11.0037 49.8298 11.1119 49.524 11.3541C48.9967 11.7716 48.7436 12.4313 48.7541 13.0807C48.7541 13.2662 48.7805 13.4518 48.8227 13.627C48.9281 14.0548 49.1338 14.4568 49.3605 14.8382C49.5345 15.1268 49.7191 15.4051 49.9195 15.6731C50.5786 16.5492 51.4012 17.312 52.3504 17.8789C52.825 18.1624 53.3365 18.4149 53.8691 18.585C54.465 18.7757 54.9923 18.9767 55.6356 18.8582C56.3053 18.7345 56.9698 18.3273 57.2387 17.6934C57.3178 17.5079 57.3547 17.2965 57.3125 17.1007C57.2229 16.6935 56.6534 16.4462 56.3159 16.2503V16.2452Z"
                                                      fill="white"/>
                                                <path
                                                    d="M15.2478 29.7746C7.08922 29.7746 0.453918 23.1393 0.453918 14.9807C0.453918 6.82211 7.08922 0.181396 15.2478 0.181396C23.4064 0.181396 30.0417 6.81669 30.0417 14.9753C30.0417 23.1339 23.4064 29.7692 15.2478 29.7692V29.7746Z"
                                                    fill="#B1B1B1"/>
                                                <path
                                                    d="M18.8409 21.07H11.6939C9.54979 21.07 8.12039 19.998 8.12039 17.4965V12.4937C8.12039 9.99221 9.54979 8.92017 11.6939 8.92017H18.8409C20.985 8.92017 22.4143 9.99221 22.4143 12.4937V17.4965C22.4143 19.998 20.985 21.07 18.8409 21.07Z"
                                                    stroke="white" strokeWidth="1.5" strokeMiterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M18.8414 12.8508L16.6044 14.6376C15.8683 15.2236 14.6604 15.2236 13.9243 14.6376L11.6944 12.8508"
                                                    stroke="white" strokeWidth="1.5" strokeMiterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M89.9083 27.6281C97.0189 27.6281 102.783 21.8639 102.783 14.7533C102.783 7.64269 97.0189 1.87842 89.9083 1.87842C82.7977 1.87842 77.0335 7.64269 77.0335 14.7533C77.0335 21.8639 82.7977 27.6281 89.9083 27.6281Z"
                                                    fill="white"/>
                                                <path
                                                    d="M89.9192 29.574C81.8148 29.574 75.2229 22.9821 75.2229 14.8777C75.2229 6.77333 81.8148 0.181396 89.9192 0.181396C98.0236 0.181396 104.616 6.77333 104.616 14.8777C104.616 22.9821 98.0236 29.574 89.9192 29.574ZM94.874 8.9092C94.2723 8.9092 93.7844 9.39709 93.7844 9.99882C93.7844 10.6005 94.2723 11.0884 94.874 11.0884C95.4757 11.0884 95.9636 10.6005 95.9636 9.99882C95.9636 9.39709 95.4757 8.9092 94.874 8.9092ZM90.0385 10.3024C87.5177 10.3024 85.4686 12.3515 85.4686 14.8723C85.4686 17.3931 87.5177 19.4422 90.0385 19.4422C92.5592 19.4422 94.6084 17.3931 94.6084 14.8723C94.6084 12.3515 92.5592 10.3024 90.0385 10.3024ZM90.0385 17.7996C88.423 17.7996 87.1111 16.4877 87.1111 14.8723C87.1111 13.2568 88.423 11.945 90.0385 11.945C91.6539 11.945 92.9658 13.2568 92.9658 14.8723C92.9658 16.4877 91.6539 17.7996 90.0385 17.7996ZM99.2596 11.0776C99.2596 7.99847 96.7659 5.50481 93.6868 5.50481H86.3305C83.2514 5.50481 80.7577 7.99847 80.7577 11.0776V18.4339C80.7577 21.513 83.2514 24.0067 86.3305 24.0067H93.6868C96.7659 24.0067 99.2596 21.513 99.2596 18.4339V11.0776ZM97.514 18.4339C97.514 20.5481 95.801 22.2611 93.6868 22.2611H86.3305C84.2163 22.2611 82.5033 20.5481 82.5033 18.4339V11.0776C82.5033 8.96341 84.2163 7.25037 86.3305 7.25037H93.6868C95.801 7.25037 97.514 8.96341 97.514 11.0776V18.4339Z"
                                                    fill="url(#paint0_linear_85_76)"/>
                                                <defs>
                                                    <linearGradient id="paint0_linear_85_76" x1="78.0418" y1="24.9879"
                                                                    x2="114.509" y2="-6.06359"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#FBE18A"/>
                                                        <stop offset="0.21" stopColor="#FCBB45"/>
                                                        <stop offset="0.38" stopColor="#F75274"/>
                                                        <stop offset="0.52" stopColor="#D53692"/>
                                                        <stop offset="0.74" stopColor="#8F39CE"/>
                                                        <stop offset="1" stopColor="#5B4FE9"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </>
    )
}

export default Footer;