// global
import './index.css';
// react
import React from 'react';
import ReactDOM from 'react-dom/client';
// router
import {BrowserRouter} from "react-router-dom";
// bootstrap
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.css';
// father component
import App from './App';
// animate
import AOS from "aos";
import 'aos/dist/aos.css';
AOS.init();
// rendering
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);
