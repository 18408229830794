// style
import './style/banner.css'
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Mousewheel} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination"
import "swiper/css/navigation"
// parallax
import {Parallax} from "../../../components/action/prallax/parallax";
// hook
import {useState} from "react";
// icons
import {IconArrowBadgeDownFilled} from "@tabler/icons-react";
// action
import {checkNumber} from "../../../components/action/checkNumber/checkNumber";
// router
import {useNavigate} from "react-router-dom";


const Banner = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();
    const handleSlideChange = (swiper: any) => {
        setActiveIndex(swiper.activeIndex);
    };
    const handleEnd = () => {
        navigate('/home');
    }
    return (
        <>
            {/* slider */}
            <article id={'banner'}
                     className={activeIndex < 5 ? 'banner h-100 slider-banner' : 'content h-100 slider-banner'}>
                {/* swiper */}
                <Swiper
                    direction={'vertical'}
                    spaceBetween={10}
                    slidesPerView={1}
                    pagination={{clickable: true}}
                    mousewheel={{forceToAxis: true, releaseOnEdges: true}}
                    speed={1500}
                    modules={[Pagination, Mousewheel]}
                    className={'h-100 slider-page'}
                    onSlideChange={handleSlideChange}
                    onReachEnd={handleEnd}
                >
                    {/* item */}
                    <SwiperSlide className={activeIndex === 0 ? 'zoom-in' : ''}>
                        {/* content */}
                        <section onMouseMove={(e) => Parallax(e, 'object')} className={'px-md-5 px-2 h-100'}>
                            <div className={'row banner-row h-100 align-items-md-center justify-content-center position-relative'}>
                                <div className={'col-lg-6 col-md-5 col-10'}>
                                    <div className={'d-flex align-items-end justify-content-end h-100'}>
                                        <img alt={'phone'}
                                             data-value={'-0.5'}
                                             className={'img-fluid image-height object'}
                                             src={require('../../../assets/media/images/banner/Slide_01_PhonePos_Vr_01 1.png')}/>
                                    </div>
                                </div>
                                <div className={'col-md-6 col-11 mt-md-0 mt-5 text-center  px-auto'}>
                                    <img alt={'text'}
                                         data-value={'1'}
                                         className={'img-fluid object'}
                                         src={require('../../../assets/media/images/banner/Slide_01_Text_Vr_01.png')}/>
                                </div>
                            </div>
                        </section>
                    </SwiperSlide>
                    {/* items */}
                    <SwiperSlide className={activeIndex === 1 ? 'zoom-out' : ''}>
                        {/* content */}
                        <section onMouseMove={(e) => Parallax(e, 'object_second')} className={'px-md-5 px-2 h-100'}>
                            <div
                                className={'row banner-row h-100 align-items-md-center justify-content-center position-relative'}>
                                <div className={'col-md-6 col-11 order-md-0 order-1'}>
                                    <img alt={'phone'}
                                         data-value={'-1'}
                                         className={'img-fluid object_second'}
                                         src={require('../../../assets/media/images/banner/second.png')}/>
                                </div>
                                <div className={'col-md-6 col-12 order-md-1 order-0 text-center'}>
                                    <div className={'d-flex align-items-end h-100 justify-content-end'}>
                                        <div className={'position-relative'}>
                                            <img alt={'phone'}
                                                 className={'img-fluid animate_img position-absolute small-img'}
                                                 src={require('../../../assets/media/images/banner/pos.png')}/>
                                            <img alt={'text'}
                                                 data-value={'0.5'}
                                                 className={'img-fluid object_second text-img-small'}
                                                 src={require('../../../assets/media/images/banner/hand.png')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </SwiperSlide>
                    {/* items */}
                    <SwiperSlide className={activeIndex === 2 ? 'zoom-in' : ''}>
                        {/* content */}
                        <section onMouseMove={(e) => Parallax(e, 'object_third')} className={'px-md-5 px-2 h-100'}>
                            <div
                                className={'row banner-row h-100 align-items-md-center justify-content-center position-relative'}>
                                <div className={'col-md-6 col-10'}>
                                    <div className={'slider-icon h-100 d-flex align-items-end'}>
                                        <img alt={'phone'}
                                             data-value={'-0.5'}
                                             className={'img-fluid object_third'}
                                             src={require('../../../assets/media/images/banner/money.png')}/>
                                    </div>
                                </div>
                                <div className={'col-md-6 col-11 text-center'}>
                                    <img alt={'text'}
                                         data-value={'1'}
                                         className={'img-fluid object_third'}
                                         src={require('../../../assets/media/images/banner/moneyText.png')}/>
                                </div>
                            </div>
                        </section>
                    </SwiperSlide>
                    {/* items */}
                    <SwiperSlide className={activeIndex === 3 ? 'zoom-out' : ''}>
                        {/* content */}
                        <section onMouseMove={(e) => Parallax(e, 'object_fourth')} className={'px-md-5 px-2 h-100'}>
                            <div
                                className={'row banner-row h-100 align-items-md-center justify-content-center position-relative'}>
                                <div className={'col-md-5 col-11 order-md-0 order-1 text-center px-md-0 px-4'}>
                                    <img alt={'text'}
                                         data-value={'-1'}
                                         className={'img-fluid object_fourth'}
                                         src={require('../../../assets/media/images/banner/moniesText.png')}/>
                                </div>
                                <div className={'col-md-7 col-10 order-md-1 order-0 mb-md-0 mb-3  text-center'}>
                                    <div className={'slider-icon h-100 d-flex justify-content-md-end justify-content-center align-items-end'}>
                                        <img alt={'phone'}
                                             data-value={'0.5'}
                                             className={'img-fluid object_fourth animate_img'}
                                             src={require('../../../assets/media/images/banner/monies.png')}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </SwiperSlide>
                    {/* items */}
                    <SwiperSlide className={activeIndex === 4 ? 'zoom-in' : ''}>
                        <section className={'px-md-5 px-2 h-100'}>
                            <div className={'row align-items-center h-100 justify-content-center'}>
                                <div className={'col-12 text-center'}>
                                    <img className={'img-fluid'} alt={'logo'}
                                         src={require('./../../../assets/media/logo/Logo_Iran Tarakonesh_Vr_33_Sefid 1.png')}/>
                                    <div className={'row mt-5 justify-content-center'}>
                                        <div className={'col-md-5 col-12'}>
                                            <div className={'d-flex justify-content-center mt-5'}>
                                                <input
                                                    onKeyUp={() => checkNumber('telephone')}
                                                    type={'tel'}
                                                    id={'telephone'}
                                                    maxLength={11}
                                                    className={'w-75 form-control login-input'}
                                                    placeholder={'شماره موبایلتون رو وارد کنید...'}
                                                />
                                                <button id={'signup-btn'}
                                                        className={'form-btn login-btn fw-bold font-medium'}>
                                                    <svg className={'ms-1'} width="24" height="24" viewBox="0 0 24 24"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.5 19.5H14.5" stroke="#171717" strokeWidth="2"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M16.5 21.5V17.5" stroke="#171717" strokeWidth="2"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path
                                                            d="M11.99 21.81C10.17 21.81 8.36004 21.35 6.98004 20.43C4.56004 18.81 4.56004 16.17 6.98004 14.56C9.73004 12.72 14.24 12.72 16.99 14.56"
                                                            stroke="#171717" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path
                                                            d="M15.6801 3.96C16.1601 4.67 16.4401 5.52 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2"
                                                            stroke="#171717" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                    </svg>
                                                    ثبت نام
                                                </button>
                                            </div>
                                            <p className={'text-white text-center mt-5 mb-1'}>
                                                اگه می خوای بدونی اینجا چه خبره ، بیا پایین...
                                            </p>
                                            <IconArrowBadgeDownFilled className={'text-white arrow-animate'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </SwiperSlide>
                    {/* items */}
                    <SwiperSlide></SwiperSlide>
                </Swiper>
            </article>
        </>
    )
}


export default Banner;