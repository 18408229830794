// component
import Banner from "../../../pages/client/banner/banner";
import Home from "../../../pages/client/home/home";

// base routes
export const routes: any = {
    client: [
        {
            label: 'اسلایدر',
            address: '/',
            component: <Banner/>,
            icon: '',
        },
        {
            label: 'خانه',
            address: '/home',
            component: <Home/>,
            icon: '',
        },
    ],
    api: [
        {
            name: 'api',
            address: '',
            group: 'api'
        },
    ]
}
