// routing
import {Route, Routes} from "react-router-dom";
// links
import {routes} from "../routes/route";

const Router = (props: any) => {
    return (
        <>
            <main className={'content slider-main'}>
                <Routes>
                    {routes.client.map((link: any) => (
                        <Route key={link.address} element={link.component} path={link.address}/>
                    ))}
                </Routes>
            </main>
        </>
    )
}

export default Router;