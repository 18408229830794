// style
import './style/home.css'
// swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay, Navigation} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination"
import "swiper/css/navigation"
// layout
import Footer from "../../../components/layout/footer/footer";
// icons
import {IconArrowBadgeUpFilled} from "@tabler/icons-react";
// hooks
import {useEffect, useState} from "react";
// router
import {useNavigate} from "react-router-dom";


const Home = () => {
    const navigate = useNavigate();
    const [isAtTop, setIsAtTop] = useState(false);
    const handleScroll = () => {
        if (window.pageYOffset === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };
    const handleWheel = (e: any) => {
        if (isAtTop && e.deltaY < 0) {
            navigate('/')
        }
    };
    const handleTouch = (e: any) => {
        if (isAtTop && e.touches[0].clientY <= 0) {
            navigate('/')
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('wheel', handleWheel);
        window.addEventListener('touchmove', handleTouch);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('touchmove', handleTouch);
        };
    }, [isAtTop]);

    return (
        <>
            <article className={'container zoom-out'}>
                <p className={'card-title text-center mb-1'}>
                    <IconArrowBadgeUpFilled className={'card-title mb-2 arrow-animate'}/>
                    <br/>
                    بازگشت به بالا...
                </p>
                <section id={'detail'} className={'mt-5'}>
                    <div className={'row justify-content-center align-items-center'}>
                        <div className={'col-md-7 mt-md-0 mt-4 order-md-0 order-1'}>
                            <h1 className={'h4 card-title fw-bold'}>
                                ایران تراکنش
                            </h1>
                            <p className={'card-text line-30 mt-3'}>
                                ایران تراکنش یه سرویس پیشرفته در بستر USSD برای انجام پرداخت‌های روزمره و
                                مدیریت تراکنش‌هاس
                                که به شما کمک می‌کنه به راحتی و با امنیت بالا، پرداخت‌های خودتون رو انجام
                                بدین. با استفاده
                                از ایران تراکنش، شما می‌تونین پرداخت‌های روزمره‌تون رو تا 100 میلیون تومن!
                                سریع و بدون دردسر
                                انجام بدین. این سرویس تمام نیازهای مالی شما رو پوشش می‌ده، از خریدهای آنلاین
                                گرفته تا
                                خریدهای حضوری و حتی پرداخت مبلغی به دوستتون.
                            </p>
                        </div>
                        <div className={'col-md-5 order-md-1 order-0 text-center'}>
                            <div className={'d-flex justify-content-end'}>
                                <img alt={'description'}
                                     src={require('./../../../assets/media/images/global/bankCart.png')}
                                     className={'img-fluid'}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section id={'supporters'} className={'mt-5'}>
                    <h4 className={'card-title text-center fw-bold'}>
                        همکاران تجاری ما
                    </h4>
                    <Swiper
                        className={'page-slider mt-4'}
                        modules={[Autoplay, Pagination, Navigation]}
                        // @ts-ignore
                        navigation={{
                            prevEl: '.next',
                            nextEl: '.prev',
                        }}
                        loop={true}
                        spaceBetween={10}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            376: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                        }}
                        pagination={{clickable: true}}
                    >
                        {/* item */}
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/Setare-Aval.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            ستاره اول
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/hamrahaval.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            همراه اول
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/irancell.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            ایرانسل
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/new-logo4.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            رایتل ، همراه هوشمند
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/shatel.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            شاتل موبایل
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/saman.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            بانک سامان
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/shahr.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            بانک شهر
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/pardakhtsaman.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            پرداخت الکترونیک سامان
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/melat_prev_ui.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            به پرداخت ملت
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/parsianEC.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            تجارت الکترونیک پارسیان
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={'item'}>
                                <div className={'text-center d-flex justify-content-center'}>
                                    <div className={'rounded rounded-circle support-circle bg-white'}>
                                        <img alt={'logo'}
                                             src={require('../../../assets/media/suporters/sazman.png')}
                                             className={'logo-support'}/>
                                    </div>
                                </div>
                                <div className={'card support-card card-body shadow border-0 mb-1'}>
                                    <div className={'card-texts text-center'}>
                                        <p className={'dark-green mt-4 fw-bold font-medium mb-0'}>
                                            سازمان فناوری اطلاعات و ارتباطات شهداری تهران
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <div className="prev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                 viewBox="0 0 34 34" fill="none">
                                <path
                                    d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z"
                                    fill="#42c7bf" fillOpacity="0.61"/>
                                <path d="M19.142 23.001L13.158 17L19.142 10.999" stroke="white"
                                      strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="next">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                 viewBox="0 0 34 34" fill="none">
                                <path
                                    d="M16.8334 33.8333C7.53661 33.8333 6.10352e-05 26.2968 6.10352e-05 17C6.10352e-05 7.70317 7.53661 0.166626 16.8334 0.166626C26.1302 0.166626 33.6667 7.70317 33.6667 17C33.6667 26.2968 26.1302 33.8333 16.8334 33.8333Z"
                                    fill="#42c7bf" fillOpacity="0.61"/>
                                <path d="M14.7124 22.9422L20.6377 17L14.7124 11.0579" stroke="white"
                                      strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </Swiper>
                </section>
                <section id={'second-detail'} className={'mt-5'}>
                    <div className={'row justify-content-center align-items-center'}>
                        <div className={'col-md-5 text-md-start text-center'}>
                            <img alt={'vijegiha'}
                                 src={require('./../../../assets/media/images/banner/Vijegiha.jpg')}
                                 className={'img-fluid'}/>
                        </div>
                        <div className={'col-md-7 mt-md-0 mt-4'}>
                            <h2 className={'h4 card-title fw-bold'}>
                                سامانه ملی امن
                            </h2>
                            <p className={'card-text line-30 mt-3'}>
                                یکی از ویژگی‌های مهم ایران تراکنش، امنیت فوق‌العاده اونه که به شما اطمینان
                                می‌ده تراکنش‌های
                                مالی‌تون به صورت امن انجام شه. علاوه بر این، سرعت بالا و کارمزدهای کمتر، از
                                دیگر مزایای این
                                سیستمه که ایران تراکنش رو به انتخابی محبوب برای کاربرا تبدیل کرده. ایران
                                تراکنش همچنین به
                                شما امکان رو می‌ده تا تراکنش‌های خودتون رو به‌راحتی پیگیری کنین و گزارش‌های
                                مالی دقیقی داشته
                                باشین. خیلی ساده بخوام بگم، با ایران تراکنش، مدیریت مالی‌تون ساده‌تر، سریع
                                تر، امن تر و
                                کارآمدتر خواهد شد!
                            </p>
                        </div>
                    </div>
                </section>
                <Footer/>
            </article>
        </>
    )
}

export default Home;